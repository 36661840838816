import React from 'react';
import DownloadButton from '../components/DownloadButton'; // Убедитесь, что путь указан правильно

const Home = () => (
    <main className="home-container">
        <div style={{height: "100px"}}/>
        <div style={{
            background: "rgba(51,51,51,0.61)",
            padding: "10px",
            borderRadius: "20px"
        }}>
            <img src="/logo192.png" alt="MatStart Logo" style={logoStyle}/>
            <h1 className="home-title">Добро пожаловать на FruitCatch!</h1>
            <p className="home-description">
                Добро пожаловать в увлекательную и яркую игру, где тебе предстоит ловить фрукты и овощи! Испытай свою
                ловкость и реакцию, собирая сочные плоды.
            </p>
            <p className="home-description">
                Важно отметить, что для использования нашего приложения вам не нужно совершать покупки или вносить
                деньги.
                Всё обучение и возможности доступны бесплатно!
            </p>
            <DownloadButton/> {/* Добавляем кнопку скачивания */}
        </div>
        <div style={{height: "100px"}}/>
    </main>
);

const logoStyle = {
    width: '150px',
    height: '150px',
};

export default Home;