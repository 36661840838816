// src/components/DownloadButton.jsx
import React from 'react';

const DownloadButton = () => (
    <a
        href="https://play.google.com/store/apps/details?id=dev.fruit.catchc.game"
        target="_blank"
        rel="noopener noreferrer"
    >
        <img
            src="/img_1.png"
            alt="Available on Google Play"
            style={iconStyle}
        />
    </a>
);

const iconStyle = {
    width: '150px', // Размер иконки
    height: 'auto',
};

export default DownloadButton;