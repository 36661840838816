import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "./components/Header";
import * as PropTypes from "prop-types";
import Reviews from "./pages/Reviews";
import Features from "./pages/Features";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import React from "react";

function Switch(props) {
    return null;
}

Switch.propTypes = {children: PropTypes.node};

function App() {
    return (
        <BrowserRouter>
            <div style={appStyle} className="App">
                {/*<Header/>*/}
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    {/*<Route path="/features" element={<Features/>}/>*/}
                    {/*<Route path="/reviews" element={<Reviews/>}/>*/}
                </Routes>
                <Footer/>
            </div>
        </BrowserRouter>
    );
}

const appStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh', // Гарантирует, что футер всегда будет внизу, если контента мало
};

export default App;
